<template>
    <v-dialog v-model="showDialog">
        <v-card v-if="order">
            <v-card-title>Picking Pedido Ref: {{order.order_reference}}</v-card-title>
            <v-card-text>
                <div class="d-flex">
                    <v-text-field @keyup.enter="toPick" v-model="scan_field" class="mr-4" label="Ingrese o escanee codigo">

                    </v-text-field>
                    <v-btn @click="toPick" class="btn-starkoms-primary">Pickear</v-btn>
                </div>
            </v-card-text>
            <v-card-text>
                <v-data-table
                    :items="tableData"
                    :headers="headers"
                >   
                <template v-slot:item.sku="{ item }">
                        <td class="text-center">
                            {{item.sku}}
                        </td>
                    </template>
                    <template v-slot:item.pickings="{ item }">
                        <td class="text-center">
                            {{item.picked_quantity}}/{{item.required}}
                        </td>
                    </template>
                    <template v-slot:item.done="{ item }">
                        <td class="text-center">
                            <v-icon v-if="item.done" color="success">mdi-checkbox-marked-circle-outline</v-icon>
                            <!--<v-icon v-if="!item.done" color="info">mdi-checkbox-marked-circle-outline</v-icon>-->
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
            <!-- <v-card-actions>
                <v-btn right class="mr-2" @click="showDialog = false">Aceptar</v-btn>
            </v-card-actions> -->

          
            <v-card-text>
                <div class="d-flex">
                    <v-text-field v-model="bags" type="number" step="1" min="1" label="Ingrese el numero de bultos">
                    </v-text-field>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn class="mr-2" @click="showDialog = false">Cancelar</v-btn>
                <v-btn class="mr-2" :disabled="loading" @click="sendForm">Guardar</v-btn>
            </v-card-actions>

        </v-card>
            
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            order: undefined,
            tableData: [],
            scan_field: undefined,
            bags: undefined,
            loading: false,
        }
    },
    created(){
        var vm = this;
        this.$parent.$on('open-dialog-pickear', (item) => {
            vm.bags = item.bags;
            this.axios({
                url: 'picking/orders/'+item.id+'/order_details',
                method: 'GET'
            }).then( response => {
                vm.order = response.data;
                vm.bags = vm.order.bags;
                vm.tableData = [];
                vm.order.order_details.forEach(element => {
                    vm.tableData.push({
                        order_detail_id: element.id,
                        sku: element.sku,
                        ean: element.ean,
                        product: element.product,
                        required: element.quantity,
                        picked_quantity: element.picked_quantity,
                        done: element.picked_quantity < element.quantity ? false : true 
                    });
                });
                vm.showDialog = true;
            }).catch( error => {
                vm.order = undefined;
            })
        });
    },
    methods:{
        toPick(){
            var vm = this;
            if(!this.scan_field) return;
            this.axios({
                url: 'picking/scan',
                method: 'POST',
                data: {
                    scan_field: this.scan_field,
                    order_id: this.order.id,
                }
            }).then( response => { // se espera: scan_field, scan_code, order_detail_id y order_completed
                vm.scan_field = undefined;
                let encontrado = vm.tableData.find(e => response.data.scan_field == e[response.data.scan_code] && !e.done && e.order_detail_id == response.data.order_detail_id);
                if(encontrado){
                    encontrado.picked_quantity++;
                    encontrado.done = encontrado.picked_quantity < encontrado.required ? false : true;
                }
                vm.checkCompleted();
            }).catch( error => {
                console.log(error);
            })
        },
        checkCompleted(){
            if( this.tableData.every(e => e.done) ){
                this.$emit('success');
            }
        },
        sendForm(){
            var vm = this;
            if(!this.bags) return;
            this.loading = true;
            this.$nextTick(() => {
                this.axios({
                    url: 'picking/orders/'+this.order.id+'/bags',
                    method: 'PUT',
                    data: {
                        bags: this.bags,
                    }
                }).then( response => { // se espera: order_completed
                    vm.loading = false;
                    vm.order.bags = vm.bags;
                    vm.$emit('success');
                    vm.$bvToast.toast('Bultos actualizados correctamente', {
                        title: `Notificación`,
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-center'
                    });
                    vm.showDialog = false;
                }).catch( error => {
                    vm.loading = false;
                    console.log(error);
                })
            })
                
        },
    },
    computed:{
        headers(){
            return [
                {text: 'Producto', value: 'product'},
                {text: 'SKU', value: 'sku'},
                {text: '# Pickings', value: 'pickings'},
                {text: 'Completado', value: 'done'},
            ]
        }
    }
}
</script>