<template>
    <v-dialog v-model="showDialog">
        <v-card v-if="order">
            <v-card-title>Actualizar Bultos Ref: {{order.order_reference}}</v-card-title>
            <v-card-text>
                <div class="d-flex">
                    <v-text-field v-model="bags" type="number" step="1" min="1" label="Ingrese el numero de bultos">
                    </v-text-field>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn class="mr-2" @click="showDialog = false">Cancelar</v-btn>
                <v-btn class="mr-2" @click="sendForm">Guardar</v-btn>
            </v-card-actions>
        </v-card>
            
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            order: undefined,
            bags: undefined,
        }
    },
    created(){
        var vm = this;
        this.bags = undefined;
        this.order = undefined;
        this.$parent.$on('open-dialog-refresh-bags', (item) => {
            vm.order = item;
            vm.bags = item.bags;
            vm.showDialog = true;
        });
    },
    methods:{
        sendForm(){
            var vm = this;
            if(!this.bags) return;
            this.axios({
                url: 'picking/orders/'+this.order.id+'/bags',
                method: 'PUT',
                data: {
                    bags: this.bags,
                }
            }).then( response => { // se espera: order_completed
                vm.order.bags = vm.bags;
                vm.$emit('success');
                vm.$bvToast.toast('Bultos actualizados correctamente', {
                    title: `Notificación`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.showDialog = false;
            }).catch( error => {
                console.log(error);
            })
        },
    },
    computed:{
        
    }
}
</script>