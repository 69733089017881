<template>
<div>
    <v-app>
        <v-card>
            <v-card-title>
                Picking Ordenes
                <v-spacer></v-spacer>
                <v-btn class @click="fetchDataPaginated" text icon color="blue">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text >
                <div class="d-flex justify-space-between mb-4">
                    <v-text-field v-model="search" clearable label="Buscar" hide-details
                        dense solo class="mr-3"
                    ></v-text-field>
                    <v-btn class="btn-starkoms-primary" style="min-width: 138px;" dark>Buscar</v-btn>
                </div>
            </v-card-text>
            <v-card-text>
                <v-data-table
                    :items="tableData"
                    :headers="headers"
                    :search="search"
                    :sort-by="'id'"
                    :sort-desc="true"
                >
                    <template v-slot:item.order_state_name="{ item }">
                        <td class="d-flex flex-column">
                            <v-btn v-if="item.current_state.type == 'DEFAULT_IN_PACKAGING'" dark color="blue">{{item.current_state.name}}</v-btn>
                            <v-btn v-if="item.current_state.type == 'DEFAULT_ON_PACKAGING'" dark color="orange">{{item.current_state.name}}</v-btn>
                            <v-btn v-if="item.current_state.type == 'DEFAULT_OUT_PACKAGING'" color="success">{{item.current_state.name}}</v-btn>
                        </td>
                    </template>
                    <template v-slot:item.source_name="{ item }">
                        <td class="text-center">
                            {{item.source.name}}
                        </td>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        <td class="text-center">
                            {{$moment(item.created_at).format('LLLL')}}
                        </td>
                    </template>
                    <template v-slot:item.updated_at="{ item }">
                        <td class="text-center">
                            {{$moment(item.updated_at).format('LLLL')}}
                        </td>
                    </template>
                    <template v-slot:item.bags="{ item }">
                        <td class="text-center">
                            {{item.bags}}
                        </td>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <td class="text-center">
                            <!-- <div v-if="item.bags === undefined || item.bags === null">
                                <v-btn @click="refreshBags(item)" color="red" text><v-icon left>mdi-pencil</v-icon>1. Completar Bultos</v-btn>
                            </div> -->
                            <!-- <div v-else>
                                <v-btn @click="refreshBags(item)" color="purple" text><v-icon left>mdi-pencil</v-icon>Actualizar Bultos</v-btn>
                            </div> -->
                            <v-btn v-if="item.current_state.type == 'DEFAULT_IN_PACKAGING' || item.current_state.type == 'DEFAULT_ON_PACKAGING'" @click="pickear(item)" color="success" text><v-icon left>mdi-arrow-right</v-icon>Pickear</v-btn>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-app>
    <dialog-pickear @success="fetchDataPaginated"></dialog-pickear>
    <dialog-refresh-bags @success="fetchDataPaginated"></dialog-refresh-bags>
</div>
</template>

<script>
import DialogPickear from '@/views/oms/Picking/DialogPickear.vue';
import DialogRefreshBags from '@/views/oms/Picking/DialogRefreshBags.vue';
export default {
    components:{
        DialogPickear,
        DialogRefreshBags
    },
    data(){
        return {
            tableData: [],
            search: ''
        }
    },
    methods: {
        fetchDataPaginated(){
            var vm = this;
            this.axios({
                url: 'picking/orders'
            }).then( response => {
                vm.tableData = response.data;
            }).catch( error => {
                console.log(error);
            })
        },
        pickear(item)
        {
            this.$emit('open-dialog-pickear', item);
        },
        refreshBags(item)
        {
            this.$emit('open-dialog-refresh-bags', item);
        }
    },
    created(){
        this.fetchDataPaginated();
    },
    computed:{
        headers(){
            return [
                {text: 'ID', value: 'id'},
                {text: 'Referencia', value: 'order_reference'},
                {text: 'Estado', value: 'order_state_name'},
                {text: 'Origen', value: 'source_name'},
                {text: 'Bultos', value: 'bags'},
                {text: 'Fecha ingreso', value: 'created_at'},
                {text: 'Fecha Actualizado', value: 'updated_at'},
                {text: 'Acciones', value: 'actions'},
            ];
        }
    }
}
</script>